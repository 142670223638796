<template>
  <div class="welcome-screen-text">
    <component :is="currentSectionRenderer" />
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';

import { useRoute } from 'vue-router';

const route = useRoute();
const sectionRendererConfig = {
  welcomeText: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/WelcomeTextSectionRenderer.vue')),
  groups: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/GroupSelectSectionRenderer.vue')),
  successText: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/SuccessTextSectionRenderer.vue')),
  inputFields: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/InputFieldsSectionRenderer.vue')),
  documents: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/DocumentsSectionRenderer.vue')),
  photo: defineAsyncComponent(() => import('../../components/dynamic-form/renderers/section/PhotoSectionRenderer.vue')),
};
const currentSectionRenderer = computed(() => sectionRendererConfig[route.params.step as keyof typeof sectionRendererConfig]);
</script>




