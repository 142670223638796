<template>
  <div class="welcome">
    <DynamicForm />
  </div>
</template>

<script setup lang="ts">
import DynamicForm from '@/components/dynamic-form/DynamicForm.vue';
import { onBeforeMount } from 'vue';
import { useVisitConditionsStore } from '../store/visit-conditions';
import { useRouter } from 'vue-router';
import { RouteName } from '../router/route-name';

// If the user has not scanned a QR code yet (no visit settings),
// redirect to the invalid QR code page
onBeforeMount(() => {
  const router = useRouter();
  const visitConditionsStore = useVisitConditionsStore();
  if (!visitConditionsStore.visitSettings) {
    router.push({ name: RouteName.InvalidQrCode });
  }
});
</script>